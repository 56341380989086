'use client';
import { PageTypes } from '@/shared/app';
import { $homePageStore } from 'app/app.model';
import { useUnit } from 'effector-react';

import { LocalizedLink } from '@/components/Link';

export const ReturnHomeLink = () => {
    const mainPage = useUnit($homePageStore);
    return (
        mainPage && (
            <LocalizedLink href={`/${mainPage?.slug === PageTypes.Home ? '' : mainPage}`}>
                Return To Main Page
            </LocalizedLink>
        )
    );
};
